import "../styles/styles.css"

import * as React from "react"

import Episodio from "../components/episodio.component"
import Footer from "../components/footer.component"
import Header from "../components/header.component"
import { episodios } from "../data/episodios.data"
import { seasons } from "../data/seasons.data"

// markup
const IndexPage = () => {
  return (
    <main id="nasondasgalegas" lang="gl-ES">
      <Header></Header>
      <div style={{ clear: "both" }}>
        {seasons.map((season) =>
          <div id={"tempada" + season.id} className="season">
            <h2 className="season-title">{season.title}</h2>
            {season.image && <img src={season.image} alt={season.title} className="season-image" />}
            {season.description && <p className="season-description">{season.description}</p>}
            {episodios
              .filter((episodio) => episodio.season === season.id)
              .map((episodio) => {
                return (
                  <Episodio
                    key={"s" + episodio.season + "e" + episodio.episode}
                    episodio={episodio}
                  ></Episodio>
                )
              })}
          </div>
        )}
      </div>
<Footer></Footer>
      <div className="nav-to-top">
        <a href="#nasondasgalegas">⇧ Volver</a>
      </div>
    </main>
  )
};

export default IndexPage;
